<template>
  <div class="wrapper">
  <div class="student-name">{{userInfo.studentName}} 同学，你好！</div>
    <div>恭喜你完成本次错题重做任务，如还存在错误答题，可继续选择重做！
      <div class="info">
        <div style="display: flex; justify-content: start"><div>
          本次答题 <span>{{tableData[0].totalCount}}</span> 道
        </div>
          <div>
            准确率：<span>{{tableData[0].correctPercentage | turnPercentage}}%</span>
          </div></div>
        <el-table :data="tableData" border header-row-class-name="header-row" class="table" row-class-name="row-class">
          <el-table-column
          prop="correctCount"
          label="正确(道)"
          align="center"
    
          width="auto"/>
          <el-table-column
          prop="wrongCount"
          label="错误(道)"
          align="center"
    
          width="auto"/>
          <el-table-column
          prop="duration"
          label="用时(分钟)"
          align="center"
    
          width="auto"/>
  
        </el-table>
      </div>
    </div>
      <div class="back">
        <span class="iconfont icon-datijilu"></span>
        <span class="link" @click="$router.replace({name: 'EntryIndex'})">查看答题记录</span>
      </div>
    
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import {RedoSummary} from "@/api/english-entry";

  export default {
    name: "answer-complete",
    data() {
      return {
        tableData: [{
          totalCount: 0,
          correctPercentage: 0
        }]
      }
    },
    
    computed: {
      ...mapState([
        'userInfo',
        'examId'
      ]),
    },
    methods: {
      getComplete() {
        RedoSummary({examId: this.examId}).then(res => {
          // res.data.result.time = ( (+new Date -sessionStorage.getItem('redoTime', +new Date())) /1000/60).toFixed(2)
          this.tableData = [res.data.result]
        })
      }
    },
    activated() {
      this.getComplete()
    },
    created() {
      this.getComplete()
    }
  }
</script>

<style lang="scss" scoped>
.wrapper{
  background-color: $bg-white;
  width: 80%;
  min-height: 675px;
}
  .student-name{
    text-align: center;
    color: $text-green;
    margin: 54px;
    font-weight: 600;
    font-size: 24px;
    &+div{
      display: inline-block;
      font-size: 22px;
      color: $text-light-gray;
      margin-top: 60px;
    }
  }
  .info{
    /*display: inline-block;*/
    /*flex-direction: column;*/
    width: 100%;
    min-width: 100%;
    margin-top: 60px;
    margin-bottom: 28px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /*justify-content: start;*/
    font-size: 18px;
    color: $text-light-gray;
    &:nth-child(1) div {
      margin-right: 40px;
    }
    span{
      color: $text-yellow;
    }
  }
  .table ::v-deep .header-row th{
    background-color: $bg-green;
    font-size: 18px;
    color: white;
  }
  .table ::v-deep .row-class {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color:$text-light-gray;
    height: 60px;
  }
  .table{
    width: 740px;
    margin: 28px auto 60px;
  }
  .back{
    .iconfont{
      font-size: 30px;
      vertical-align: middle;
    }
    font-size: 20px;
    color: $text-light-gray;
    .link{
      cursor: pointer;
      text-decoration: underline;
    }
  }
</style>
